<template>
  <el-card class="card">
    <template #header>
      <div>
        <span>申请列表</span>
      </div>
    </template>

    <div class="table-container">
      <el-table
      :data="state.applyList"
      stripe
      style="width: 100%">
        <el-table-column
        type="index"
        prop=""
        label="序号"
        width="70">
        </el-table-column>
        <el-table-column prop="status" sortable label="状态">
          <template #default="scope">
            <span v-if="scope.row.status == null">暂未处理</span>
            <span v-if="scope.row.status == '0'">暂未处理</span>
            <span v-if="scope.row.status == '1'">通过</span>
            <span v-if="scope.row.status == '2'">拒绝</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" sortable label="昵称"></el-table-column>
        <el-table-column prop="sex" sortable label="性别">
          <template #default="scope">
            <span v-if="scope.row.sex == '1'">男</span>
            <span v-if="scope.row.sex == '2'">女</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" sortable label="手机号"></el-table-column>
        <el-table-column prop="idNumber" sortable label="身份证号"></el-table-column>
        <el-table-column prop="image" sortable label="照片">
          <template #default="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src=scope.row.image
              :fit="fit"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" sortable label="创建时间"></el-table-column>
        <el-table-column prop="updateTime" sortable label="修改时间"></el-table-column>
        <el-table-column
        label="操作"
        align="center"
        width="200">
          <template #default="scope">
            <el-popconfirm title="确定通过该请求吗？" @confirm="pass(scope.$index)">
              <template #reference>
                <el-button size="small" plain type="primary">&nbsp;通过</el-button>
              </template>
            </el-popconfirm>
            <el-popconfirm title="确定拒绝该请求吗？" @confirm="reject(scope.$index)">
              <template #reference>
                <el-button size="small" plain type="danger">&nbsp;拒绝</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { ElMessage } from "element-plus";
import http from "@/util/http.js";

const state = reactive({
  applyList: [],
})

onMounted(() => {
  select();
});

const pass = (index) => {
  let apply = state.applyList[index];
  // console.log(apply);
  http.post(
    "/admin/apply/submit/1",
    apply,
    {
      headers: {'token': JSON.parse(sessionStorage.getItem("userInfo")).token}
    })
    .then((res) => {
      select();
      ElMessage.success("操作成功")
    }).catch((err) => {
      console.log(err);
      ElMessage.error("操作失败");
    });
}

const reject = (index) => {
  let apply = state.applyList[index];
  // console.log(apply);
  http.post(
    "/admin/apply/submit/2",
    apply,
    {
      headers: {'token': JSON.parse(sessionStorage.getItem("userInfo")).token}
    })
    .then((res) => {
      select();
      ElMessage.success("操作成功")
    }).catch((err) => {
      console.log(err);
      ElMessage.error("操作失败");
    });
}

const select = () => {
  let apply = {
      status: "0"
  }
  http.post(
    "/admin/apply/select",
    apply,
    {
      headers: {'token': JSON.parse(sessionStorage.getItem("userInfo")).token}
    })
    .then((res) => {
    //更新表格
    state.applyList = [];
    let list = [];
    list = res.data.data;
    for (let i=0; i<list.length; i++) {
      state.applyList[i] = list[i];
    }
    // console.log(state.applyList);
  }).catch((err) => {
    // console.log(err);
    ElMessage.error("获取数据失败");
  });
}
</script>

<style scoped>
.card {
  top: 100px;
}

.table-container {
  text-align: right;
}
</style>
