//对axios进行简单封装
import axios from 'axios'

// 创建 axios 实例
const http = axios.create({
// baseURL: 'http://localhost',
baseURL: 'https://www.htw0o.com',
headers: {
    get: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    post: {
        'Content-Type': 'application/json;charset=utf-8',
    },
},
// 是否跨站点访问控制请求
withCredentials: false,
timeout: 30000,
})

export default http;

