<template>
  <!--系统管理员首页: index页面框架布局-->
  <div class="index">
    <!--页面组成：其位置由各自组件的css定义-->
    <header class="header">
      <el-row>
        <!--左侧logo区域-->
        <el-col :span="12" class="logo-container">
          <img src="@/assets/logo.png" class="logo" />
          <span class="title">管理控制台</span>
        </el-col>
        <!--右侧用户信息区域-->
        <el-col :span="12" class="user">
          <span>工号：{{this.$store.state.jobNumber}}</span>&nbsp;&nbsp;
          <el-link :underline="false" type="primary">
            <el-icon><switch-button /></el-icon>
          </el-link>
        </el-col>
      </el-row>
    </header>
    <div>
      <el-row className="menu_page">
        <el-col>
          <el-menu
            className="el-menu-vertical"
            background-color="#324057"
            text-color="#fff"
            active-text-color="#409eff"
          >
            <!--el-submenu带有子菜单的菜单组-->
            <!--el-menu-item直接是菜单项-->
            <!--点击router-link,可以跳转到指定的url-->
            <router-link to="/apply">
              <el-menu-item index="0">
                <i className="el-icon-s-home"></i>
                <span><slot name="title">申请列表</slot></span>
              </el-menu-item>
            </router-link>
            
            <router-link to="/applyHistory">
              <el-menu-item index="1">
                <i className="el-icon-s-home"></i>
                <span><slot name="title">申请记录</slot></span>
              </el-menu-item>
            </router-link>

            <router-link to="/userinfo">
              <el-menu-item index="5">
                <i className="el-icon-user"></i>
                <span><slot name="title">个人信息</slot></span>
              </el-menu-item>
            </router-link>
          </el-menu>
        </el-col>
      </el-row>
    </div>
    <div class="container">
      <!--通过router-view加载其他组价-->
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>

</script>
<style scoped>
/*定义index页面的布局及样式*/
.index {
  width: 100%;
  overflow: hidden;
}
.container {
  position: relative;
  top: 0;
  left: 220px;
  padding: 20px;
  box-sizing: border-box;
  width: calc(100% - 220px);
  height: calc(100% - 71px);
  overflow: auto;
}
/*头部样式*/
.header {
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  min-width: 600px;
  padding: 5px;
  background: #324057;
  color: #fff;
  border-bottom: 1px solid #324057;
}
.logo-container {
  line-height: 60px;
  min-width: 400px;
}
.logo {
  height: 50px;
  width: 50px;
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;
}
.title {
  vertical-align: middle;
  font-size: 22px;
  letter-spacing: 3px;
}
.user {
  line-height: 60px;
  text-align: right;
  float: right;
  padding-right: 20px;
}
/*左侧菜单样式*/
.menu_page {
  box-sizing: border-box;
  position: fixed;
  top: 72px;
  left: 0;
  width: 220px;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #324057;
  z-index: 99;
}
.el-menu {
  border: none;
}
.el-menu-vertical:not(.el-menu--collapse) {
  width: 220px;
  min-height: 400px;
  margin: 0;
  padding: 0;
}
.el-menu-vertical {
  margin: 0;
  width: 220px;
  margin: 0;
  padding: 0;
}
.el-submenu .el-menu-item {
  min-width: 220px;
}
.el-menu-item {
  width: 220px;
}
a {
  text-decoration: none;
}
.el-menu-item.is-active {
  background-color: #296a4d;
}
</style>