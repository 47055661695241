import { createApp } from 'vue'
import App from '@/App.vue'
const app = createApp(App);
// 全局引入ElementPlus
import ElementPlus from 'element-plus';
app.use(ElementPlus);
// 全局引入element-pluscss样式
import 'element-plus/dist/index.css';
// 全局引入router路由
import myRouter from '@/router/myRouter.js';
app.use(myRouter);
// 全局引入store
import store from '@/store/index.js'
app.use(store);
// 全局注册element-plus图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// 全局定义自定义组件

// 挂载vue实例到html div容器上
app.mount('#app')
