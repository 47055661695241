//定义全局路由: 页面组件之间的跳转逻辑关系
import { createWebHistory, createWebHashHistory, createRouter } from 'vue-router'

//需要路由的页面组件,都需要导入,再定义路由关系
import Login from "@/views/Login.vue"
import AdminIndex from "@/views/AdminIndex.vue"
import Apply from "@/components/Apply.vue"
import ApplyHistory from "@/components/ApplyHistory.vue"
import UserInfo from "@/components/UserInfo.vue"

//定义路由组件
const myRouter = createRouter({
    history: createWebHashHistory(),// 路由模式
    routes: [
        {
            path: '/', //浏览器访问 / 时,重定向到/login
            redirect: 'login'
        },
        {//所有的组件要跳转，必须先命名路由
            path: '/login', //访问的url
            name: 'login',
            component: Login //页面组件名称
        },
        {//所有的组件要跳转，必须先命名路由
            path: '/index',
            name: 'index',
            component: AdminIndex,
            children: [//adminindex组件包含的子组件
                { path: '/apply', name: 'apply', component: Apply },
                { path: '/applyHistory', name: 'applyHistory', component: ApplyHistory },
                { path: '/userinfo', name: 'userinfo', component: UserInfo},
            ]
        },
    ]
})
//全局路由守护，必须登录后，才能访问vue中的页面组件
myRouter.beforeEach(async (to) => {
    let userInfo = sessionStorage.getItem('userInfo');
    // 检查用户是否已登录 ，并且要访问的url不等于/login 避免无限重定向
    if (!userInfo && to.path !== '/login') {
        // 重定向到登录页面
        return { path: '/login' }
    }
})
export default myRouter;
