<template>
  <el-card class="card">
    <template #header>
      <div>
        <span>个人信息</span>
      </div>
    </template>
    <el-row>
      <el-col :span="3">
        <div>
          <el-avatar :size="100">
            <el-icon size="80"><Avatar /></el-icon>
          </el-avatar>
        </div>
      </el-col>
      <el-col :span="18">
        <div>
          <span style="line-height: 2em">工&#12288;&#12288;号：&nbsp;{{this.$store.state.jobNumber}}</span>
          <br>
          <span style="line-height: 2em" v-if="this.$store.state.level == 0">账号类型：&nbsp;高级管理员</span>
          <span style="line-height: 2em" v-if="this.$store.state.level == 1">账号类型：&nbsp;普通管理员</span>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script setup>

</script>

<style scoped>
.card {
  position: fixed;
  top: 100px;
  width: 85%;
  min-width: 400px;
}


</style>