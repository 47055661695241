import { createStore } from 'vuex'

const store = createStore({
    // 准备actions-用于响应组件中的动作
    actions: {
        id(context, value) {
            context.commit('ID', value)
        },
        jobNumber(context, value) {
            context.commit('JOBNUMBER', value)
        },
        level(context, value) {
            context.commit('LEVEL', value)
        },
        token(context, value) {
            context.commit('TOKEN', value)
        },
        createId(context, value) {
            context.commit('CREATID', value)
        },
        updateId(context, value) {
            context.commit('UPDAEID', value)
        },
        createTime(context, value) {
            context.commit('CREATIDTIME', value)
        },
        updateTime(context, value) {
            context.commit('UPDAEIDTIME', value)
        },
    },
    // 准备mutations-用于加工数据(state)
    mutations: {
        ID(state, value) {
            state.id = value
        },
        JOBNUMBER(state, value) {
            state.jobNumber = value
        },
        LEVEL(state, value) {
            state.level = value
        },
        TOKEN(state, value) {
            state.token = value
        },
        CREATID(state, value) {
            state.createId = value
        },
        UPDAEID(state, value) {
            state.updateId = value
        },
        CREATIDTIME(state, value) {
            state.createTime = value
        },
        UPDAEIDTIME(state, value) {
            state.updateTime = value
        },
    },
    // 准备state-用于存储数据(state)
    state() {
        return {
            id: '',
            jobNumber: '',
            level: '',
            token: '',
            createId: '',
            updateId: '',
            createTime: '',
            updateTime: '',
        }
    }
})

export default store

