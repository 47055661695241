<template>
  <div>
    <!--登录框div的样式-->
    <div class="login">
      <el-card class="loginCard" shadow="always">
        <el-form :model="adminLoginDTO" :rules="myrule" ref="loginFormRef">
          <h2 style="text-align: center">日常记录</h2>
          <el-form-item prop="jobNumber">
            <el-input
              type="text"
              v-model="adminLoginDTO.jobNumber"
              auto-complete="off"
              placeholder="登录账号"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="adminLoginDTO.password"
              auto-complete="off"
              placeholder="登录密码"
            >
            </el-input>
          </el-form-item>
          <el-form-item></el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="login(loginFormRef)"
              style="width: 100%"
            >
              登 录
            </el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <div class="bottom">
      <a href="http://beian.miit.gov.cn/">冀ICP备2024060304号</a>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs } from "vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex"
import { useRouter } from "vue-router";
import http from "@/util/http.js";
const store = useStore();
const router = useRouter();

//把表单定义为一个响应数据对象
const loginFormRef = ref();
const adminLoginDTO = reactive({
  jobNumber: "",
  password: "",
});
const myrule = ref({
  jobNumber: [{ required: true, message: "请输入登录账号", trigger: "blur" }],
  password: [{ required: true, message: "请输入登录密码", trigger: "blur" }],
});

//登录验证函数
const login = () => {
  // console.log(adminLoginDTO);
  http.post("/login/admin", JSON.stringify(adminLoginDTO)).then((res) => {
    // console.log(res);
    if (res.data.code == "1") {
      //验证成功
      //创建一个本地会话，存放用户信息
      sessionStorage.setItem("userInfo", JSON.stringify(res.data.data));
      // console.log(JSON.parse(sessionStorage.getItem("userInfo")));

      //路由跳转
      router.push("/apply");

      //将返回的数据存放到state中供全局使用
      store.dispatch('id', res.data.data.id)
      store.dispatch('jobNumber', res.data.data.jobNumber)
      store.dispatch('level', res.data.data.level)
      store.dispatch('token', res.data.data.token)
      store.dispatch('createId', res.data.data.createId)
      store.dispatch('createTime', res.data.data.createTime)
      store.dispatch('updateId', res.data.data.updateId)
      store.dispatch('updateTime', res.data.data.updateTime)

      // console.log(store.state);
    } else {
      //验证失败
      ElMessage.error({
        message: res.data.msg,
      });
      //路由跳转:跳转到重新登录页面
      router.push("/login");
    }
  });
};
</script>
<style scoped>
.bottom {
  position: fixed;
  bottom: 30px;
  right: 45%;
}
.login {
  width: 100%;
  height: 100%;
}
.loginCard {
  position: relative;
  top: 150px;
  width: 500px;
  margin: 0 auto;
  padding: 0 20px;
}
</style>
